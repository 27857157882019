// WOFF ID
import { woffId } from "@/config";

/**
 * Initialize the app by calling functions handling individual app components
 */
const initializeApp = () => {
 
}

/**
* Initialize WOFF
* @param {string} myWoffId The WOFF ID of the selected element
*/
const initializeWoff = woffId => {
    woff
        .init({
            woffId
        })
        .then(() => {
            // start to use WOFF's api
            initializeApp()
        })
        .catch((err) => {
            console.error(err)
        });
}

// On load
window.addEventListener('load', () => {
    console.log('WOFF_ID: ' + woffId);
    initializeWoff(woffId);
});
