import router from "./";
import { Session } from "@/utils/storage"
import { redirect_url } from '@/config'

router.beforeEach((to, from, next) => {
    // 外部浏览器环境中运行时需要执行woff.login方法
    setTimeout(() => {
        if (woff.isLoggedIn()) {
            next()
        } else {
            woff.login({ redirectUri: `${redirect_url}/${to.fullPath}` });
        }
    }, 500)
})
