import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './lang/zh.js'
import jpn from './lang/jpn.js'
Vue.use(VueI18n)

localStorage.locale = localStorage.locale ? localStorage.locale : ('jpn' || 'zh')
let language = localStorage.locale || 'zh'
const i18n = new VueI18n({
  locale: language || 'zh',
  messages: {
    zh: {
      ...zh
    },
    jpn: {
      ...jpn
    }
  }
})

export default i18n
