export default {
    // 异常页面
    'exception.403': '抱歉，你无权访问该页面',
    'exception.404': '抱歉，你访问的页面不存在或仍在开发中',
    'exception.500': '抱歉，服务器出错了',
    'exception.user-not-exit': '用户不存在',

    // 酒精测量
    'alcMeasure.concentration': '酒精浓度',
    'alcMeasure.alcoholUrl': '酒精测量照片',
    'alcMeasure.alcoholUrlRequired': '请上传酒精测量照片',
    'alcMeasure.error': '酒精异常',

    // 测量履歴 
    'alcResume.confirm': '已确认',
    'alcResume.unconfirm': '未确认',
    'alcConfirm.comment': '备注',
    'login.success': '登録しました',

    // 通用
    'common.optSuccessText': '承認しました',
    'common.perv': '上一页',
    'common.next': '下一页',
    'common.pageSize': '{pageSize}件/页',
}