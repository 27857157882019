<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch : {
    $route : {
      handler(route) {
        this.$nextTick(() => {
          document.title = route.name || '安心ドライバー'
        })
      },
      deep: true
    }
  }
}
</script>

<style>
#app {
  position: relative;
  font-family: BIZ UDPGothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  max-width: 600px;
  min-height: 100%;
  background-color: #fff;
}
</style>
