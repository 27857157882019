const routes = [
  // 根路由
  {
    path: '/',
    redirect: '/alcMeasure'
  },
  {
    path: '/alcMeasure',
    name: 'ノンアル',
    component: () => import('@/views/alcMeasure/index.vue')
  },
  {
    path: '/alcResume',
    name: '履歴',
    component: () => import('@/views/alcResume/index.vue')
  },
  {
    path: '/alcConfirm',
    name: '履歴確認',
    component: () => import('@/views/alcConfirm/index.vue')
  }
]

export default routes