export default {
    // 异常页面
    'exception.403': '申し訳ありませんが、該当ページのアクセス権限がありません',
    'exception.404': '申し訳ありませんが、該当ページは存在しない',
    'exception.500': '申し訳ありませんが、サーバーエラーが発生しました',
    'exception.user-not-exit': '該当ユーザーは存在しません',
    'exception.browerTips': 'お使いのブラウザは対応していません',

    // 酒精测量
    'alcMeasure.concentration': 'アルコール濃度',
    'alcMeasure.alcoholUrl': 'ALC測定写真',
    'alcMeasure.alcoholUrlRequired': 'ALC測定写真をアップロードしてください',
    'alcMeasure.error': 'ALC異常',

    // 测量履歴 
    'alcResume.confirm': '承認済',
    'alcResume.unconfirm': '未承認',
    'alcConfirm.comment': 'コメント',
    'login.success': '登録しました',

     // 通用
    'common.optSuccessText': '承認しました',
    'common.perv': '前へ',
    'common.next': '次へ',
    'common.pageSize': '{pageSize}件/ページ',
}