import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/router/permission'
import '@/styles/init.css'
import Vant from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import { Toast } from 'vant'
import 'vant/lib/index.css';
import i18n from './i18n'
import './utils/woff'

Vue.use(Vant)

Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$Toast = Toast

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
