import Cookies from 'js-cookie';

/**
 * window.sessionStorage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export const Session = {
	// 设置临时缓存
	set(key, val) {
		if (key === 'token' || key === 'refresh_token') {
			Cookies.set(key, val);
		}
		window.sessionStorage.setItem(key, JSON.stringify(val));
	},
	// 获取临时缓存
	get(key) {
		if (key === 'token' || key === 'refresh_token') return Cookies.get(key);
		let json = window.sessionStorage.getItem(key);
		return JSON.parse(json);
	},
	// 移除临时缓存
	remove(key) {
		if (key === 'token' || key === 'refresh_token') return Cookies.remove(key);
		window.sessionStorage.removeItem(key);
	},
	// 移除全部临时缓存
	clear() {
		Cookies.remove('token');
		Cookies.remove('refresh_token');
		window.sessionStorage.clear();
	},
	// 获取当前存储的 token
	getToken() {
		return this.get('token');
	}
};

